import React, { useState } from "react"

const LANGUAGE_STORAGE_KEY = "lng"

export enum Locales {
  EN = "en",
  NB = "nb",
}

export const getCulture = () => {
  return window.navigator.languages
    ? window.navigator.languages[0]
    : window.navigator.language ||
        window.navigator["userLanguage"] ||
        window.navigator["browserLanguage"]
}

const languageDictionary = {
  [Locales.EN]: ["en", "en-US", "en-GB"],
  [Locales.NB]: ["nb-NO", "nb", "nn-NO", "nn", "se-NO", "nb-SJ"],
}

const detectLanguageDictionary = () => {
  const culture = getCulture()

  let locale: Locales = Locales.EN

  for (const key of Object.keys(languageDictionary)) {
    const languageDictionaryToUpper = languageDictionary[key].map(el =>
      el.toUpperCase()
    )

    if (languageDictionaryToUpper.includes(culture.toUpperCase())) {
      locale = key as Locales
    }
  }

  return locale
}

const defaultState = {
  language:
    typeof window !== "undefined"
      ? localStorage.getItem(LANGUAGE_STORAGE_KEY) || Locales.EN
      : Locales,
  changeLanguage: (language: Locales) => {
    if (typeof window !== "undefined") {
      localStorage.setItem(LANGUAGE_STORAGE_KEY, language)
    }
  },
}

const returnLang = () => {
  if (typeof window !== "undefined") {
    if (localStorage.getItem(LANGUAGE_STORAGE_KEY))
      return localStorage.getItem(LANGUAGE_STORAGE_KEY)
    else {
      const locale = detectLanguageDictionary()
      localStorage.setItem(LANGUAGE_STORAGE_KEY, locale)

      return locale
    }
  } else {
    return Locales.EN
  }
}

export const LanguageContext = React.createContext({
  language: Locales.EN,
  changeLanguage: (language: Locales) => {},
})

export const ContextProvider: React.FC = ({ children }) => {
  const [language, setLanguage] = useState(Locales.EN)

  const changeLanguage = (language: Locales) => {
    setLanguage(language)
    localStorage.setItem(LANGUAGE_STORAGE_KEY, language)
  }

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  )
}
